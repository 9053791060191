<script setup>
import { onMounted } from "vue";
import { setPageTitle } from "../../utils/pageTitle";
import { useRouter } from "vue-router";
import Bins from "./Bins.vue";
import PageTitleCard from "@/components/PageTitleCard.vue";

const router = useRouter();

onMounted(() => {
  setPageTitle("Bins");
});
</script>

<template>
  <PageTitleCard justifyCard="space-between">
    <template #actions>
      <v-btn
        flat
        class="font-weight-bold mr-2"
        color="primary"
        @click="router.push('/bins/new')"
        >New BIN</v-btn
      >
    </template>
  </PageTitleCard>
  <div id="bins-grid-wrapper">
    <Bins />
  </div>
</template>

<style scoped>
#bins-grid-wrapper {
  height: 100%;
  padding-top: 0.25%;
}
</style>